export const UPLOAD_CSV_REQUEST = 'UPLOAD_CSV_REQUEST';
export const UPLOAD_CSV_SUCCESS = 'UPLOAD_CSV_SUCCESS';
export const UPLOAD_CSV_FAIL = 'UPLOAD_CSV_FAIL';

export const FETCH_GIFTCARDS_REQUEST = 'FETCH_GIFTCARDS_REQUEST';
export const FETCH_GIFTCARDS_SUCCESS = 'FETCH_GIFTCARDS_SUCCESS';
export const FETCH_GIFTCARDS_FAIL = 'FETCH_GIFTCARDS_FAIL';

export const DELETE_GIFTCARD_REQUEST = 'DELETE_GIFTCARD_REQUEST';
export const DELETE_GIFTCARD_SUCCESS = 'DELETE_GIFTCARD_SUCCESS';
export const DELETE_GIFTCARD_FAIL = 'DELETE_GIFTCARD_FAIL';
