export const ALL_GIFT_CARDS_REQUEST = 'ALL_GIFT_CARDS_REQUEST';
export const ALL_GIFT_CARDS_SUCCESS = 'ALL_GIFT_CARDS_SUCCESS';
export const ALL_GIFT_CARDS_FAIL = 'ALL_GIFT_CARDS_FAIL';

export const GIFT_CARD_DETAILS_REQUEST = 'GIFT_CARD_DETAILS_REQUEST';
export const GIFT_CARD_DETAILS_SUCCESS = 'GIFT_CARD_DETAILS_SUCCESS';
export const GIFT_CARD_DETAILS_FAIL = 'GIFT_CARD_DETAILS_FAIL';

export const GIFT_CARD_UPDATE_REQUEST = 'GIFT_CARD_UPDATE_REQUEST';
export const GIFT_CARD_UPDATE_SUCCESS = 'GIFT_CARD_UPDATE_SUCCESS';
export const GIFT_CARD_UPDATE_FAIL = 'GIFT_CARD_UPDATE_FAIL';

export const GIFT_CARD_DELETE_REQUEST = 'GIFT_CARD_DELETE_REQUEST';
export const GIFT_CARD_DELETE_SUCCESS = 'GIFT_CARD_DELETE_SUCCESS';
export const GIFT_CARD_DELETE_FAIL = 'GIFT_CARD_DELETE_FAIL';

export const GIFT_CARD_CREATE_REQUEST = 'GIFT_CARD_CREATE_REQUEST';
export const GIFT_CARD_CREATE_SUCCESS = 'GIFT_CARD_CREATE_SUCCESS';
export const GIFT_CARD_CREATE_FAIL = 'GIFT_CARD_CREATE_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';
